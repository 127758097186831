@use "./colors.scss" as colors;
@use "./fonts.scss" as fonts;
@import "./themes/theme-controller.scss";

.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/*THEMES*/

// Future funk
.app[theme="future-funk"] {
  @include theme-controller($theme-name: "ff");
}

// Dracula
.app[theme="dracula"] {
  @include theme-controller($theme-name: "dracula");
}

// RyujinScales
.app[theme="ryujin"] {
  @include theme-controller($theme-name: "rs");
}

// Grape
.app[theme="grape"] {
  @include theme-controller($theme-name: "grape");
}

// DMG
.app[theme="froyo-dark"] {
  @include theme-controller($theme-name: "fd");
}
