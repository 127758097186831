@use "./displaySizes.scss" as sizes;

.footer-container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0rem 2.5rem;
}

.footer-content {
  width: 100%;
  max-width: 1800px;
  height: fit-content;
  position: relative;

  .social-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 3rem;
    position: absolute;
    bottom: 0;
    left: 2rem;

    &::after {
      content: "";
      display: block;
      width: 0.1rem;
      height: 7rem;
    }

    .link {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    @media (max-width: sizes.$tablet) {
      display: none;
    }
  }

  .toggle-button-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 2rem;
    width: 3rem;
    z-index: 2;

    button {
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2.6rem;
      padding: 0rem;

      span {
        margin: 1rem 0;
        writing-mode: vertical-lr;
      }

      &::after {
        content: "";
        display: block;
        width: 0.1rem;
        height: 7rem;

        @media (max-width: sizes.$phone) {
          height: 3rem;
        }
      }
    }
  }
}
