@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Major+Mono+Display&family=Noto+Sans+Mono:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100;200;300;400&display=swap");

// Fonts
$Poppins: "Poppins", sans-serif;
$Josefin: "Josefin Sans", sans-serif;
$ShareTechMono: "Share Tech Mono", sans-serif;
$MajorMono: "Major Mono Display", sans-serif;
$NotoSansMono: "Noto Sans Mono", sans-serif;
