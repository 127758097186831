@use "./fonts.scss" as fonts;
@use "./colors.scss" as colors;

* {
  box-sizing: border-box;
  margin: 0;
  font-size: 62.5%;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  scrollbar-width: thin;
}

body {
  overflow: hidden;
  background: colors.$black;

  // Future funk
  &[theme="future-funk"] {
    background: colors.$ff-bg-color;
  }

  // Dracula
  &[theme="dracula"] {
    background: colors.$dracula-bg-color;
  }

  // RyujinScales
  &[theme="ryujin"] {
    background: colors.$rs-bg-color;
  }

  // Grape
  &[theme="grape"] {
    background: colors.$grape-bg-color;
  }

  // DMG
  &[theme="froyo-dark"] {
    background: colors.$fd-bg-color;
  }
}
