@use "../colors.scss" as colors;
@use "../fonts.scss" as fonts;
@use "../displaySizes.scss" as sizes;

$color-list: (
  //
  // GRAPE
  "grape-bg-color": #221223,
  "grape-header-bg-color": #221223,
  "grape-textMain": #f8eee7,
  "grape-textSecondary": #f4decb,
  "grape-primaryAccent": #d68cce,
  "grape-secondaryAccent": #9873c4,
  "grape-tertiaryAccent": #765de7,
  "grape-theme-list-bg": rgba(214, 140, 206, 0.1),
  "grape-scrollbar-color": #684664,
  //
  // FROYO-DARK
  "fd-bg-color": #1b180f,
  "fd-header-bg-color": #1b180f,
  "fd-textMain": #e1dacb,
  "fd-textSecondary": #f7f2ea,
  "fd-primaryAccent": #ff7e73,
  "fd-secondaryAccent": #08d9a3,
  "fd-tertiaryAccent": #f5c370,
  "fd-theme-list-bg": rgba(8, 217, 161, 0.1),
  "fd-scrollbar-color": #219274,
  //
  // RYUJIN
  "rs-bg-color": #081426,
  "rs-header-bg-color": #081426,
  "rs-textMain": #efeae9,
  "rs-textSecondary": #f7f2ea,
  "rs-primaryAccent": #f17754,
  "rs-secondaryAccent": #f17754,
  "rs-tertiaryAccent": #f17754,
  "rs-theme-list-bg": rgba(241, 118, 84, 0.1),
  "rs-scrollbar-color": #a4614f,
  //
  // DRACULA
  "dracula-bg-color": #282a36,
  "dracula-header-bg-color": #282a36,
  "dracula-textMain": #f2f2f2,
  "dracula-textSecondary": #f7f2ea,
  "dracula-primaryAccent": #bd93f9,
  "dracula-secondaryAccent": #50fa7b,
  "dracula-tertiaryAccent": #ec75c4,
  "dracula-theme-list-bg": rgba(189, 147, 249, 0.1),
  "dracula-scrollbar-color": #7a649a,
  //
  // FUTURE-FUNK
  "ff-bg-color": #2e1a47,
  "ff-header-bg-color": #2e1a47,
  "ff-textMain": #f7f2ea,
  "ff-textSecondary": #f7f2ea,
  "ff-primaryAccent": #f04e98,
  "ff-secondaryAccent": #a4dbe8,
  "ff-tertiaryAccent": #f6eb61,
  "ff-theme-list-bg": rgba(240, 78, 151, 0.1),
  "ff-scrollbar-color": #a19b43
);

@mixin theme-controller($theme-name) {
  * {
    ::-webkit-scrollbar-thumb {
      background-color: map-get($color-list, $theme-name + "-scrollbar-color");
      transition: background-color 200ms linear;
    }
    scrollbar-color: map-get($color-list, $theme-name + "-scrollbar-color")
      map-get($color-list, $theme-name + "-bg-color");
    transition: scrollbar-color 200ms linear;
  }

  .page-container {
    background-color: map-get($color-list, $theme-name + "-bg-color");
    transition: background-color 200ms linear;
  }

  h1 {
    color: map-get($color-list, $theme-name + "-primaryAccent");
    transition: color 200ms linear;

    span {
      color: map-get($color-list, $theme-name + "-tertiaryAccent");
      transition: color 200ms linear;
    }
  }

  h2 {
    color: map-get($color-list, $theme-name + "-secondaryAccent");
    transition: color 200ms linear;
  }

  h3 {
    color: map-get($color-list, $theme-name + "-textMain");
    transition: color 200ms linear;
  }

  .details {
    ul {
      span {
        color: map-get($color-list, $theme-name + "-secondaryAccent");
        transition: color 200ms linear;
      }
    }
  }

  .skill-list {
    .skill {
      background-color: map-get($color-list, $theme-name + "-theme-list-bg");
      transition: color 200ms linear;
    }
  }

  .project-details {
    background-color: map-get($color-list, $theme-name + "-theme-list-bg");
    transition: background-color 200ms linear;

    .title-container {
      .project-link {
        svg {
          fill: map-get($color-list, $theme-name + "-textMain");
          transition: fill 200ms linear;

          &:hover {
            fill: map-get($color-list, $theme-name + "-secondaryAccent");
            transition: fill 200ms linear;
          }
        }
      }
    }

    .project-description {
      color: map-get($color-list, $theme-name + "-textMain");
      transition: color 200ms linear;
    }

    .project-tools {
      .tool {
        background-color: map-get(
          $color-list,
          $theme-name + "-header-bg-color"
        );
        transition: color 200ms linear;
        color: map-get($color-list, $theme-name + "-primaryAccent");
        transition: color 200ms linear;
      }
    }
  }

  .name-chars,
  .title {
    &:hover {
      color: map-get($color-list, $theme-name + "-tertiaryAccent");
      transition: color 200ms linear;
    }
  }

  .navbar-container {
    background-color: map-get($color-list, $theme-name + "-header-bg-color");
    transition: background-color 200ms linear;

    .logo-container {
      a {
        color: map-get($color-list, $theme-name + "-tertiaryAccent");
        transition: color 200ms linear;

        &:hover {
          color: map-get($color-list, $theme-name + "-secondaryAccent");
        }
      }
    }

    .nav-list {
      a {
        color: map-get($color-list, $theme-name + "-textMain");
        transition: color 200ms linear;

        &:hover {
          color: map-get(
            $color-list,
            $theme-name + "-secondaryAccent"
          ) !important;
        }
      }

      li {
        span {
          color: map-get($color-list, $theme-name + "-secondaryAccent");
          transition: color 200ms linear;
        }
      }
    }

    .nav-list-container-mobile {
      background-color: map-get($color-list, $theme-name + "-theme-list-bg");
      transition: background-color 200ms linear;
    }

    .nav-list-mobile {
      li {
        a {
          color: map-get($color-list, $theme-name + "-textMain") !important;

          span {
            color: map-get(
              $color-list,
              $theme-name + "-primaryAccent"
            ) !important;
          }
        }
      }
    }

    .social-links {
      fill: map-get($color-list, $theme-name + "-primaryAccent");
      transition: fill 200ms linear;
    }

    .nav-toggle-container {
      .toggle-button-container {
        .toggle-button {
          .hamburger {
            background: map-get($color-list, $theme-name + "-secondaryAccent");

            &::before {
              background: map-get(
                $color-list,
                $theme-name + "-secondaryAccent"
              );
            }

            &::after {
              background: map-get(
                $color-list,
                $theme-name + "-secondaryAccent"
              );
            }
          }
        }
      }
    }
  }

  .theme-container {
    .theme-list-container {
      button {
        color: map-get($color-list, $theme-name + "-textMain");
        transition: color 200ms linear;
      }

      li {
        span {
          color: map-get($color-list, $theme-name + "-primaryAccent");
          transition: color 200ms linear;
        }
      }

      .theme-list {
        background-color: map-get($color-list, $theme-name + "-theme-list-bg");
        transition: background-color 200ms linear;
        backdrop-filter: blur(15px);
      }
    }
  }

  .toggle-button-container {
    button {
      font-family: fonts.$MajorMono;
      color: map-get($color-list, $theme-name + "-textMain");
      transition: color 200ms linear;

      &::after {
        background-color: map-get($color-list, $theme-name + "-tertiaryAccent");
        transition: background-color 200ms linear;
      }
    }
  }

  .contact-statement {
    p {
      color: map-get($color-list, $theme-name + "-textMain");
      transition: color 200ms linear;
    }
  }

  .email-button {
    svg {
      fill: map-get($color-list, $theme-name + "-tertiaryAccent");
      transition: fill 200ms linear;
    }

    a {
      color: map-get($color-list, $theme-name + "-tertiaryAccent");
      border-color: map-get(
        $color-list,
        $theme-name + "-tertiaryAccent"
      ) !important;

      transition: border-color 200ms linear;
      transition: color 200ms linear;

      h3 {
        color: map-get($color-list, $theme-name + "-tertiaryAccent");
        transition: color 200ms linear;
      }

      &:hover {
        color: map-get($color-list, $theme-name + "-secondaryAccent");
        border-color: map-get(
          $color-list,
          $theme-name + "-secondaryAccent"
        ) !important;
        background-color: map-get($color-list, $theme-name + "-theme-list-bg");
        transition: background-color 200ms linear;
        transition: border-color 200ms linear;

        h3 {
          color: map-get($color-list, $theme-name + "-secondaryAccent");
          transition: color 200ms linear;
        }

        svg {
          fill: map-get($color-list, $theme-name + "-secondaryAccent");
          transition: fill 200ms linear;
        }
      }
    }
  }

  .resume-button {
    svg {
      fill: map-get($color-list, $theme-name + "-textMain");
      transition: fill 200ms linear;
    }

    a {
      color: map-get($color-list, $theme-name + "-secondaryAccent");
      border-color: map-get(
        $color-list,
        $theme-name + "-secondaryAccent"
      ) !important;
      background-color: map-get($color-list, $theme-name + "-theme-list-bg");

      transition: border-color 200ms linear;
      transition: color 200ms linear;

      h3 {
        color: map-get($color-list, $theme-name + "-textMain");
        transition: color 200ms linear;
      }

      &:hover {
        color: map-get($color-list, $theme-name + "-tertiaryAccent");
        border-color: map-get(
          $color-list,
          $theme-name + "-tertiaryAccent"
        ) !important;
        background-color: map-get($color-list, $theme-name + "-theme-list-bg");
        transition: background-color 200ms linear;
        transition: border-color 200ms linear;

        h3 {
          color: map-get($color-list, $theme-name + "-tertiaryAccent");
          transition: color 200ms linear;
        }

        svg {
          fill: map-get($color-list, $theme-name + "-tertiaryAccent");
          transition: fill 200ms linear;
        }
      }
    }
  }

  .footer-container {
    .social-links {
      &::after {
        background-color: map-get($color-list, $theme-name + "-tertiaryAccent");
        transition: color 200ms linear;
      }

      .link {
        fill: map-get($color-list, $theme-name + "-textMain");
        transition: fill 200ms linear;

        &:hover {
          fill: map-get($color-list, $theme-name + "-tertiaryAccent");
          transition: fill 200ms linear;
        }
      }
    }
  }

  .social-links {
    &::after {
      background-color: map-get($color-list, $theme-name + "-tertiaryAccent");
      transition: color 200ms linear;
    }

    svg {
      fill: map-get($color-list, $theme-name + "-textMain");
      transition: color 200ms linear;

      &:hover {
        color: map-get($color-list, $theme-name + "-tertiaryAccent");
        transition: color 200ms linear;
      }
    }

    @media (max-width: sizes.$tablet) {
      h2 {
        color: map-get($color-list, $theme-name + "-textMain");
        transition: color 200ms linear;
      }

      svg {
        fill: map-get($color-list, $theme-name + "-primaryAccent");
        transition: color 200ms linear;
      }
    }
  }
}
