$background: #999;
$text: rgb(237, 237, 237);
$black: rgb(14, 14, 14);
$grey: rgb(14, 14, 14);
$white: #d4d5d5;
$shadow: rgba(0, 0, 0, 0.348);
$ff-bg-color: #2e1a47;
$dracula-bg-color: #282a36;
$rs-bg-color: #081426;
$fd-bg-color: #1b180f;
$grape-bg-color: #221223;
