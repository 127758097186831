@use "./fonts.scss" as fonts;
@use "./colors.scss" as colors;
@use "./displaySizes.scss" as sizes;

.theme-container {
  position: absolute;
  bottom: 0;
  right: 2rem;
  display: flex;
  text-align: left;
  align-items: center;
  overflow: hidden;
  visibility: visible;

  @media (max-width: sizes.$phone) {
    right: 1rem;
  }

  li {
    button {
      background: none;
      border: none;
      font-family: fonts.$MajorMono;
      font-size: 2.2rem;
    }
  }

  .theme-list-container {
    position: relative;
    z-index: 2;
  }

  .theme-list {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 4rem 2rem;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    margin-right: 4rem;
    list-style: none;
  }

  .toggle-button-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 3rem;
    z-index: 2;

    button {
      background: none;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2.6rem;
      padding: 0rem;

      span {
        margin: 1rem 0;
        writing-mode: vertical-lr;
      }

      &::after {
        content: "";
        display: block;
        width: 0.1rem;
        height: 7rem;

        @media (max-width: sizes.$phone) {
          height: 3rem;
        }
      }
    }
  }
}
